import {INPUT_IDS} from "@/modules/meilisearch/meilisearch.core";
import {getInputValue, getLocale} from "@/utility/helper";
import appConfig from "@/config";

/**
 * Event listener show current location on focus input search
 * */
/*eventListener(document.getElementById(INPUT_IDS.SEARCH_BOX),
  'focus', () => {
    const defaultLocation = document.getElementById(INPUT_IDS.HITS_DEFAULT_LOCATION_DROPDOWN);
    const searchBox = document.getElementById(INPUT_IDS.SEARCH_BOX) as HTMLInputElement;
    // when focus on search box and input not empty show dropdown location
    if (searchBox.value.length) {
      const meiliHits = document.getElementById(INPUT_IDS.HITS_DROPDOWN) as HTMLDivElement
      if (meiliHits) {
        meiliHits.classList.remove('hidden');
      }
    } else {
      // else show default location dropdown
      if (defaultLocation) {
        defaultLocation.classList.remove('hidden');
      }
    }
  });*/

export type meilisearchEventType = 'click' | 'focus' | 'blur';
/**
 * Event listener
 * */
export const meilisearchEvents = (
  event: meilisearchEventType = 'click',
  idElement = 'meili-search',
) => {
  /** show current location on focus input search */
  if (event === 'focus') {
    const defaultLocation = document.getElementById(`meili-default-location-hits-${idElement}`)
    const searchBox = document.getElementById(idElement) as HTMLInputElement;
    // when focus on search box and input not empty show dropdown location
    if (searchBox.value.length) {
      const meiliHits = document.getElementById(`meili-hits-${idElement}`) as HTMLDivElement
      if (meiliHits) {
        meiliHits.classList.remove('hidden');
      }
    } else {
      // else show default location dropdown
      if (defaultLocation) {
        defaultLocation.classList.remove('hidden');
      }
    }
  }
  /** search box blur and close dropdown */
  else if (event === 'blur') {
    clearDropdown(idElement);
  }
}

/**
 * Event listener on click current location
 * */
export const onSelectedCurrentLocation = () => {
  let tenure = getInputValue(INPUT_IDS.TENURE);
  let locale = getLocale();
  navigator.geolocation.getCurrentPosition(
    (pos) => {
      window.location.replace(`${appConfig.domain}/${locale}/for-${tenure}?map=` +
        (pos.coords.longitude - 0.05) + "," + (pos.coords.longitude + 0.05) + "," +
        (pos.coords.latitude - 0.05) + "," + (pos.coords.latitude + 0.05));
    })
}


/**
 * Event Listener on search box blur and close dropdown
 * */
/*
eventListener(document.getElementById(INPUT_IDS.SEARCH_BOX),
  'blur', () => {
    clearDropdown();
  });
*/

/**
 * Clear dropdown search box
 * */
export const clearDropdown = (idElement: string) => {
  const meiliHits = document.getElementById(`meili-hits-${idElement}`) as HTMLDivElement
  const meiliDefaultLocationHits = document.getElementById(`meili-default-location-hits-${idElement}`) as HTMLDivElement
  if (!meiliHits) return;
  setTimeout(() => {
    meiliHits.classList.add('hidden')
    meiliDefaultLocationHits.classList.add('hidden')
  }, 300);
}

